import adminApiClient from '../../api/admin-api-client'
import { BaseCollection } from '../BaseCollection'
import { CreateEditHostingShortcutParam } from './CreateEditHostingShortcutParam'
import { HostingShortcut } from './HostingShortcut'
import { EventBus } from '../../util/EventBus'

export class HostingShortcutCollection extends BaseCollection {
  constructor(props) {
    super(props)

    this.companyId = props.companyId || ''
    this.collectionKey = 'shortcutId'
  }

  /**
   * @override
   * Get list of hosting shortcuts
   * @returns {HostingShortcut[]}
   */
  async fetch() {
    this.isLoading = true
    try {
      const response = await adminApiClient.getHostingShortcuts(this.companyId, {
        Page: this.page,
        PageSize: this.pageSize,
        OrderBy: this.orderBy,
        Filter: this.filter,
      })
      this.count = response.data.count
      this.items = response.data.data.map((c) => new HostingShortcut(c))
      this.isLoading = false
      if (this.filter) {
        EventBus.$emit('restoreFocusEvent')
      }
      return this.items
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }

  /**
   * @override
   * Create a new hosting shortcut
   * @param {HostingShortcut} shortcut
   */
  async create(shortcut) {
    shortcut.isLoading = true
    const param = new CreateEditHostingShortcutParam(shortcut)
    delete param.shortcutId
    this.isLoading = true
    try {
      let response = await adminApiClient.createHostingShortcut(this.companyId, param)

      if (response.status === 200) {
        const newShortcut = new HostingShortcut(response.data)
        this.items.push(newShortcut)
        this.added = newShortcut
        shortcut.isLoading = false
        this.isLoading = false
      } else {
        throw new Error('Failed to create shortcut')
      }
    } catch (ex) {
      shortcut.isLoading = false
      this.isLoading = false
      throw ex
    }
  }

  /**
   * @override
   * @param {HostingShortcut} record The shortcut to update
   */
  async update(record) {
    try {
      this.isLoading = true
      await record.update(this.companyId)

      this.updateItem(record, this.collectionKey)
      this.updated = record
      this.isLoading = false
      return this.updated
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }

  /**
   * @override
   * Delete hosting shortcut
   * @param {Number} shortcutId
   */
  async delete(shortcutId) {
    this.isLoading = true
    try {
      await adminApiClient.deleteHostingShortcut(this.companyId, shortcutId)
      let stillInArray = this.items.findIndex((shortcut) => shortcut.shortcutId === shortcutId)
      if (stillInArray > -1) {
        this.removed = this.items[stillInArray]
        this.items.splice(stillInArray, 1)
      }
      this.isLoading = false
      return true
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }
}
